var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row cover",staticStyle:{"margin":"0 30px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"col-sm-6 cover-child question-box"},[_c('div',{staticClass:"question",staticStyle:{"opacity":"1"}},[_c('div',{staticClass:"question-title"},[_vm._l((_vm.question_tags),function(title,index){return _c('span',{key:index,class:{
            active: _vm.question_detail.question_order === index + 1,
            view_question: true,
            wrong: title.is_correct == 0
          },on:{"click":function($event){return _vm.viewResolve(title.toefl_question_id)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),_c('hr')],2),_c('div',{staticClass:"reading_default"},[_vm._m(0),(_vm.question_detail.content.repeat_audio !== null)?_c('div',[_c('audio',{attrs:{"src":_vm.question_detail.content.repeat_audio,"controls":""}})]):_vm._e(),_c('h4',{staticClass:"q_title",domProps:{"innerHTML":_vm._s(_vm.question_detail.question)}}),(_vm.question_detail.type == 'default')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[(
                _vm.isCorrect(
                  option.id,
                  _vm.question_detail.user_answers,
                  _vm.question_detail.is_correct
                )
              )?_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):(
                _vm.isError(
                  option.id,
                  _vm.question_detail.user_answers,
                  _vm.question_detail.is_correct
                )
              )?_c('div',{staticClass:"alert alert-danger"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])]):_c('div',[_c('div',{class:_vm.question_detail.answers[0].answers.includes(option.id)
                    ? 'alert-success alert'
                    : 'alert'},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])])}),0):_vm._e(),(_vm.question_detail.type == 'sort')?_c('div',{staticClass:"options"},_vm._l((_vm.question_detail.options),function(option){return _c('div',{key:option.id,staticClass:"radio"},[_c('div',{staticClass:"alert alert-success"},[_c('label',{attrs:{"for":option.id}},[_vm._v(_vm._s(option.title))])])])}),0):_vm._e(),(_vm.question_detail.type == 'multiple_choice')?_c('div',{staticClass:"options"},[_c('table',{staticClass:"table table-bordered"},[_c('tbody',_vm._l((_vm.question_detail.content.options),function(option,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(option))]),_vm._l((_vm.lines),function(i,iIndex){return _c('td',{key:iIndex},[_c('div',{staticClass:"radio"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.question_detail.options[index * _vm.lines + iIndex].title))])])])])})],2)}),0)])]):_vm._e(),_c('div',{staticClass:"answer_content"},[_c('div',{staticClass:"answers"},[_vm._v(" My Answer: "),_c('b',{domProps:{"innerHTML":_vm._s(
                _vm.getMyAnswer(
                  _vm.question_detail.user_answer_title,
                  _vm.question_detail.answer_title,
                  _vm.question_detail.is_correct,
                )
              )}})]),_c('div',{staticClass:"correctAnswer"},[_vm._v(" Correct Answer: "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.getCorrectAnswer(_vm.question_detail.answer_title))}})]),_c('div',{staticClass:"clear"})]),_c('hr'),_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Explanation:")]),_c('div',{staticClass:"exp"},[_vm._v(" "+_vm._s(_vm.question_detail.exp)+" ")])])])])]),_c('div',{class:{
      'col-sm-12': _vm.showPassage,
      'col-sm-6': !_vm.showPassage,
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"passage"},[_c('div',{staticClass:"audio"},[_c('audio',{attrs:{"src":_vm.question_detail.chapter_content.audio,"controls":""}})]),_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.question_detail.chapter_title))]),_c('div',{attrs:{"id":"passage"},domProps:{"innerHTML":_vm._s(_vm.question_detail.chapter_content.text)}}),_c('div',{staticClass:"open",on:{"click":function($event){_vm.showPassage = !_vm.showPassage}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"text"},[_vm._v("展开")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassage),expression:"showPassage"}],staticClass:"text"},[_vm._v("收起")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Question:")])])
}]

export { render, staticRenderFns }