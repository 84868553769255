<template>
  <div class="row cover" style="margin:0 30px">
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="question" style="opacity: 1;">
        <div class="question-title">
          <span
            v-for="(title, index) in question_tags"
            @click="viewResolve(title.toefl_question_id)"
            :key="index"
            :class="{
              active: question_detail.question_order === index + 1,
              view_question: true,
              wrong: title.is_correct == 0
            }"
          >
            {{ index + 1 }}
          </span>
          <hr />
        </div>
        <div class="reading_default">
          <div class="resolve_content">
            <div class="title">Question:</div>
          </div>
          <div v-if="question_detail.content.repeat_audio !== null">
            <!-- <span class="btn play"><i class="far fa-play-circle"></i></span> -->
            <audio :src="question_detail.content.repeat_audio" controls></audio>
          </div>
          <h4 class="q_title" v-html="question_detail.question"></h4>
          <div class="options" v-if="question_detail.type == 'default'">
             <div
              class="radio"
              v-for="option in question_detail.options"
              :key="option.id"
            >
              <div
                class="alert alert-success"
                v-if="
                  isCorrect(
                    option.id,
                    question_detail.user_answers,
                    question_detail.is_correct
                  )
                "
              >
                <label :for="option.id">{{ option.title }}</label>
              </div>
              <div
                class="alert alert-danger"
                v-else-if="
                  isError(
                    option.id,
                    question_detail.user_answers,
                    question_detail.is_correct
                  )
                "
              >
                <label :for="option.id">{{ option.title }}</label>
              </div>
              <div v-else>
                <div
                  :class="
                    question_detail.answers[0].answers.includes(option.id)
                      ? 'alert-success alert'
                      : 'alert'
                  "
                >
                  <label :for="option.id">{{ option.title }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="options" v-if="question_detail.type == 'sort'">
            <div
              class="radio"
              v-for="option in question_detail.options"
              :key="option.id"
            >
              <div class="alert alert-success">
                <label :for="option.id">{{ option.title }}</label>
              </div>
            </div>
          </div>
          <div class="options" v-if="question_detail.type == 'multiple_choice'">
            <table class="table table-bordered">
              <tbody>
                <tr
                  v-for="(option, index) in question_detail.content.options"
                  :key="index"
                >
                  <td style="vertical-align: middle;">{{ option }}</td>
                  <td v-for="(i, iIndex) in lines" :key="iIndex">
                    <div class="radio">
                      <div>
                        <label>{{
                          question_detail.options[index * lines + iIndex].title
                        }}</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="answer_content">
            <div class="answers">
              My Answer:
              <b
                v-html="
                  getMyAnswer(
                    question_detail.user_answer_title,
                    question_detail.answer_title,
                    question_detail.is_correct,
                  )
                "
              ></b>
            </div>
            <div class="correctAnswer">
              Correct Answer:
              <b v-html="getCorrectAnswer(question_detail.answer_title)"></b>
            </div>
            <div class="clear"></div>
          </div>
          <hr />
          <div class="resolve_content">
            <div class="title">Explanation:</div>
            <div class="exp">
              {{ question_detail.exp }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="passage">
        <div class="audio">
          <!-- <span class="btn play"><i class="far fa-play-circle"></i></span> -->
          <audio :src="question_detail.chapter_content.audio" controls></audio>
        </div>
        <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
        <div id="passage" v-html="question_detail.chapter_content.text"></div>
        <div class="open" @click="showPassage = !showPassage">
          <div class="text" v-show="!showPassage">展开</div>
          <div class="text" v-show="showPassage">收起</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import ToeflMixin from "@/mixins/toefl.js";

export default {
  props:["question_detail", "question_tags"],
  mixins: [ToeflMixin],
  computed:{
    lines(){
      let lines = 2;
      if(this.question_detail.options && this.question_detail.content.options){
        lines = this.question_detail.options.length / this.question_detail.content.options.length;
      }
      return lines;
    }
  },
  data() {
    return {
      showPassage: false
    };
  },
  watch: {},

  mounted() {},

  methods: {
    isCorrect(answers,user_answers, isCorrect) {
      let correct = false;
      if(isCorrect === 1){
        if(user_answers.includes(answers)){
          correct = true;
        };
      }
      return correct;
    },
    isError(answers,user_answers, isCorrect) {
      let error = false;
      if(isCorrect === 0){
        if(user_answers.includes(answers) && !this.question_detail.answers[0].answers.includes(answers) ){
          error = true;
        };
      }
      return error;
    },
    viewResolve(question_id) {
      this.$parent.viewResolve(question_id);
    }
  }
};
</script>

<style scoped>
.play {
  font-size: 30px;
  color: #43a06b;
}
.answer_content .answers {
  margin-right: 20px;
}
</style>
