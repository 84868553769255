var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row cover",staticStyle:{"margin":"0 30px"}},[_c('div',{class:{
      'col-sm-12': _vm.showPassage,
      'col-sm-6': !_vm.showPassage,
      'cover-child': true,
      'passage-box': true
    }},[_c('div',{staticClass:"passage"},[_c('div',[_vm._m(0),_c('h6',{staticClass:"article",domProps:{"innerHTML":_vm._s(_vm.question_detail.question)}}),_c('hr')]),_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Question:")]),_c('h6',{domProps:{"innerHTML":_vm._s(_vm.question_detail.content.article)}}),_c('hr')]),(_vm.question_detail.type == 'integrated')?_c('div',[_c('div',[_vm._m(1),_c('audio',{attrs:{"src":_vm.question_detail.content.audio,"controls":""}}),_c('h6',[_c('div',{staticClass:"article",domProps:{"innerHTML":_vm._s(_vm.question_detail.content.audio_text)}})])]),_c('hr')]):_vm._e(),_c('div',{staticClass:"open",on:{"click":function($event){_vm.showPassage = !_vm.showPassage}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"text"},[_vm._v("展开")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassage),expression:"showPassage"}],staticClass:"text"},[_vm._v("收起")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassage),expression:"!showPassage"}],staticClass:"col-sm-6 cover-child question-box"},[_c('div',{staticClass:"question",staticStyle:{"opacity":"1"}},[_c('div',{staticClass:"speaking_resolve"},[_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("My Answer:")]),_c('div',{staticClass:"exp"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.setText(_vm.question_detail.user_answers[0]))}})])]),_c('hr'),_c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Sample Answer:")]),_c('div',{staticClass:"exp"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.question_detail.exp)}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Directions:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resolve_content"},[_c('div',{staticClass:"title"},[_vm._v("Audio:")])])
}]

export { render, staticRenderFns }