<template>
  <div class="resolve">
    <reading
      v-if="question_detail.skill_name == 'reading'"
      :question_detail="question_detail"
      :question_tags="question_tags"
    ></reading>
    <listening
      v-if="question_detail.skill_name == 'listening'"
      :question_detail="question_detail"
      :question_tags="question_tags"
    ></listening>
    <speaking
      v-if="question_detail.skill_name == 'speaking'"
      :question_detail="question_detail"
      :question_tags="question_tags"
    ></speaking>
    <writing
      v-if="question_detail.skill_name == 'writing'"
      :question_detail="question_detail"
      :question_tags="question_tags"
    ></writing>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import "./style.css";
import Reading from "./Reading";
import Listening from "./Listening";
import Speaking from "./Speaking";
import Writing from "./Writing";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Resolve - Ivy-Way Academy"
    };
  },

  components: {
    Reading,
    Listening,
    Speaking,
    Writing
  },
  mixins: [],
  props: [],
  // 46Listening 75Speaking
  data() {
    return {
      question_detail: {},
      question_tags: []
    };
  },
  computed: {
    user_exam_id() {
      return this.$route.query.user_exam_id;
    },
    question_id() {
      return this.$route.query.question_id;
    }
  },
  watch: {},

  mounted() {
    this.getResolve();
  },

  methods: {
    async getResolve() {
      const res = await TOEFL.getResolve(this.user_exam_id, {
        toefl_question_id: this.question_id
      });
      this.question_tags = res.question_tags;
      this.question_detail = res.question;
    },
    viewResolve(question_id) {
      this.routerPush({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id
        }
      });
    }
  }
};
</script>

<style scoped>
.resolve {
  background: #f2f3f5;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>
