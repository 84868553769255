<template>
  <div class="row cover" style="margin:0 30px">
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="question" style="opacity: 1;">
        <div class="question-title">
          <span
            v-for="(title, index) in question_tags"
            @click="viewResolve(title.toefl_question_id)"
            :key="index"
            :class="{
              active: question_detail.question_order === index + 1,
              view_question: true,
              wrong: title.is_correct == 0 || !title.is_correct
            }"
          >
            {{ index + 1 }}
          </span>
          <hr />
        </div>
        <div class="reading_default">
          <div class="resolve_content">
            <div class="title">Question:</div>
          </div>
          <h5 class="q_title" v-html="question_detail.question"></h5>
          <p class="sentence" v-if="question_detail.type == 'click'">
            {{ question_detail.content.click_option }}
          </p>
          <div class="options">
            <div
              class="radio"
              v-for="option in question_detail.options"
              :key="option.id"
            >
              <div
                class="alert alert-success"
                v-if="
                  isCorrect(
                    option.id,
                    question_detail.user_answers,
                    question_detail.is_correct
                  )
                "
              >
                <label :for="option.id">{{ option.title }}</label>
              </div>
              <div
                class="alert alert-danger"
                v-else-if="
                  isError(
                    option.id,
                    question_detail.user_answers,
                    question_detail.is_correct
                  )
                "
              >
                <label :for="option.id">{{ option.title }}</label>
              </div>
              <div v-else>
                <div :class="showAlert(option.id)">
                  <label :for="option.id">{{ option.title }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="answer_content">
            <div class="answers">
              My Answer:
              <b
                v-if="question_detail.user_answers"
                v-html="
                  getMyAnswer(
                    question_detail.user_answer_title,
                    question_detail.answer_title,
                    question_detail.is_correct,
                  )
                "
              ></b>
              <b v-else class="text-danger">
                *
              </b>
            </div>
            <div class="answers">
              Correct Answer:
              <b v-html="getCorrectAnswer(question_detail.answer_title)"></b>
            </div>
            <div class="clear"></div>
          </div>
          <hr />
          <div class="resolve_content">
            <div class="title">Explanation:</div>
            <div class="exp">
              {{ question_detail.exp }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="passage">
        <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
        <div id="passage"></div>
        <div class="open" @click="showPassage = !showPassage">
          <div class="text" v-show="!showPassage">展开</div>
          <div class="text" v-show="showPassage">收起</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import ToeflMixin from "@/mixins/toefl.js";
export default {
  props:["question_detail", "question_tags"],
  mixins: [ToeflMixin],
  computed:{},
  data() {
    return {
      showPassage: false
    };
  },
  watch: {
    question_detail() {
      this.setPassage();
    }
  },

  mounted() {
    this.setPassage();
  },

  methods: {
    showAlert(id) {
      if(this.question_detail.answers[0].answers.includes(id)) {
        return "alert-success alert";
      } else if(this.question_detail.answers[1] && this.question_detail.answers[1].answers.includes(id)) {
        return "alert-success alert alert-blue";
      } else {
        return "alert";
      }
    },
    isCorrect(answers,user_answers, isCorrect) {
      let correct = false;
      if(isCorrect === 1){
        if(user_answers.includes(answers)){
          correct = true;
        };
      }
      return correct;
    },
    isError(answers,user_answers, isCorrect) {
      let error = false;
      if(isCorrect === 0){
        if(user_answers.includes(answers) && !this.question_detail.answers[0].answers.includes(answers) ){
          error = true;
        };
      }
      return error;
    },
    setPassage() {
      let passage = document.getElementById("passage");
      passage.innerHTML = this.question_detail.content.html;
      this.scrollToParagraph();
      if (this.question_detail.type === "click") {
        let dom = document.getElementsByClassName("insert-area");
        for (var i = 0; i < dom.length; i++) {
          dom[i].innerHTML = ` <b class="text-success">[${dom[i].getAttribute(
            "data-answer"
          )}]</b> `;
        }
      }
    },
    //段落跳转
    scrollToParagraph() {
      let paragraph = document.getElementsByClassName("paragraph-anchor")[0];
      let text = document.getElementsByClassName("passage")[0];
      if (paragraph !== undefined) {
        text.scrollTo(0, paragraph.offsetTop);
      }
    },
    viewResolve(question_id) {
      this.$parent.viewResolve(question_id);
    }
  }
};
</script>

<style scoped>
.answer_content .answers {
  margin-right: 20px;
}
.alert-blue {
  color: white;
  background-color: #409eff;
  border-color: #409eff;
}
::v-deep .light {
  background-color: #43a06b;
  color: #fff;
}
::v-deep .article .article {
  display: inline;
}
</style>
