<template>
  <div class="row cover" style="margin:0 30px">
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="passage">
        <!-- <h2 class="text-center">{{ question_detail.chapter_title }}</h2> -->
        <div v-if="question_detail.type == 'article_audio'">
          <div class="resolve_content">
            <div class="title">Passage:</div>
          </div>
          <h6 v-html="question_detail.content.reading"></h6>
          <hr />
        </div>
        <div
          v-if="
            question_detail.type == 'article_audio' ||
              question_detail.type == 'audio'
          "
        >
          <div>
            <div class="resolve_content">
              <div class="title">Audio:</div>
            </div>
            <audio
              :src="question_detail.content.talking_audio"
              controls
            ></audio>
            <h6>
              <div
                class="article"
                v-html="question_detail.content.talking_audio_text"
              ></div>
            </h6>
          </div>
          <hr />
        </div>
        <div class="resolve_content">
          <div class="title">Question:</div>
          <h5>{{ question_detail.question }}</h5>
        </div>
        <div id="passage"></div>
        <div class="open" @click="showPassage = !showPassage">
          <div class="text" v-show="!showPassage">展开</div>
          <div class="text" v-show="showPassage">收起</div>
        </div>
      </div>
    </div>
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="question" style="opacity: 1;">
        <div class="speaking_resolve">
          <div class="resolve_content">
            <div class="title">My Answer:</div>
            <div class="exp">
              <audio :src="question_detail.user_answers[0]" controls></audio>
            </div>
          </div>
          <hr />
          <div class="resolve_content">
            <div class="title">Sample Answer:</div>
            <div class="exp">
              <div v-html="question_detail.exp"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props:["question_detail", "answers"],
  created(){
  },
  computed:{},
  data() {
    return {
      showPassage: false
    };
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.speaking_resolve {
  margin-top: 30px;
}
</style>
